import React from 'react';

const Logo = () => (
    <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => window.location.href = '/'}>
        <img
            src="/Logo.png"
            alt="NextDoor Dukan Logo"
            className="w-12 h-12 sm:w-16 sm:h-16 md:w-18 md:h-18 lg:w-20 lg:h-20"
        />
        <div className="flex flex-col items-start">
            <h1 className="text-lg sm:text-xl md:text-2xl text-[#501F21] font-carter-one">
                NextDoor{' '}
                <span className="font-eczar font-semibold">
                    दुकान
                </span>
            </h1>
            <p className="text-xs sm:text-sm text-[#000000] font-carter-one mt-0 sm:mt-1">
                Empowering Kirana Stores
            </p>
        </div>
    </div>
);

export default Logo;