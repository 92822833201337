import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import ContactUs from './ContactUs';
import { translations } from './LanguageSwitcher';
import ResponsiveAppBar from './ResponsiveAppBar';
import { CartProvider } from './CartContext';
import ProductsPage from './Products';
import Join from './JoinUs';
import Careers from './Careers';
import AboutUs from './page_components/AboutUs';
import CreateOrder from './CreateOrder';
import CategoryPage from './CategoryPage';
import ProductList from './ProductList';
import { SearchProvider } from './SearchContext';
import Terms from './Terms';
import { LocationProvider } from './page_components/LocationComponent';
import Footer from './Footer';
import Team from './page_components/Team';
import HelpSupport from './page_components/HelpSupport';
import ProfilePage from './ProfilePage';
import PrivateRoute from './PrivateRoute';
import AddAddressPage from './AddressModal';

const PageLayout = ({ children }) => (
  <div className="w-full flex justify-center">
    <div className="w-full max-w-[1920px] px-4 md:px-6 lg:px-8">
      {children}
    </div>
  </div>
);

const Home = () => (
  <PageLayout>
    <ProductsPage/>
  </PageLayout>
);

const Order = ({ t, pincode, setPincode, handlePincodeSubmit }) => (
  <PageLayout>
    <div className="order-form flex flex-col items-center px-4 md:px-6 lg:px-8">
      <p className="text-center text-base md:text-lg lg:text-xl mb-4">{t.enterPincode}</p>
      <form onSubmit={handlePincodeSubmit} className="w-full max-w-sm md:max-w-md lg:max-w-lg">
        <input
          type="text"
          placeholder={t.pincodePrompt}
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          className="pincode-input w-full p-2 md:p-3 text-sm md:text-base"
        />
        <button type="submit" className="submit-button w-full mt-2 md:mt-4 p-2 md:p-3">
          {t.submit}
        </button>
      </form>
    </div>
  </PageLayout>
);

const About = ({t}) => (
  <PageLayout>
    <AboutUs t={t}/>
  </PageLayout>
);

// Create a wrapper component that conditionally renders the footer
const AppLayout = ({ children }) => {
  const location = useLocation();
  
  // List of routes where footer should be hidden
  const noFooterRoutes = [
    '/profile',
    '/orders',
    '/addresses',
    '/edit-profile',
    '/wallet',
    '/referrals',
    '/support',
    '/add-address',
    '/edit-address'
  ];
  
  // Check if current path starts with any of the no-footer routes
  const shouldHideFooter = noFooterRoutes.some(route => 
    location.pathname === route || 
    (route !== '/' && location.pathname.startsWith(route + '/'))
  );

  return (
    <div className="w-full pt-4 md:pt-6 lg:pt-10 min-h-screen bg-white">
      <div className="w-full flex flex-col items-center">
        <div className="w-full max-w-[1920px]">
          <header className="w-full flex justify-center px-4 md:px-6 lg:px-8">
            <ResponsiveAppBar />
          </header>
          <main className="w-full pb-14 md:pb-0">
            {children}
          </main>
          {!shouldHideFooter && <Footer/>}
        </div>
      </div>
    </div>
  );
};

function App() {
  const [pincode, setPincode] = useState('');

  const handlePincodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://pincode-nextdoordukan.azurewebsites.net/api/pincode`, { pincode });
      alert(`Coming soon to your Pincode: ${pincode}`);
      console.log(response.data);
    } catch (err) {
      console.error('Error saving pincode:', err);
    }
  };
  const language = 'English';
  const t = translations['English'];

  return (
    <CartProvider>
      <SearchProvider>
        <LocationProvider>
          <Router>
            <Routes>
              <Route path="/" element={
                <AppLayout>
                  <Home />
                </AppLayout>
              } />
              <Route path="/about" element={
                <AppLayout>
                  <About t={t} />
                </AppLayout>
              } />
              <Route path="/join" element={
                <AppLayout>
                  <Join />
                </AppLayout>
              } />
              <Route path="/contact" element={
                <AppLayout>
                  <PageLayout>
                    <ContactUs language={language} />
                  </PageLayout>
                </AppLayout>
              } />
              <Route path="/team" element={
                <AppLayout>
                  <Team/>
                </AppLayout>
              } />
              <Route path="/careers" element={
                <AppLayout>
                  <Careers />
                </AppLayout>
              } />
              <Route path="/helpsupport" element={
                <AppLayout>
                  <HelpSupport />
                </AppLayout>
              } />
              <Route path="/order" element={
                <AppLayout>
                  <Order 
                    t={t} 
                    pincode={pincode} 
                    setPincode={setPincode} 
                    handlePincodeSubmit={handlePincodeSubmit} 
                  />
                </AppLayout>
              } />
              <Route path="/order/create/utt-pos-work-conc-2025" element={
                <AppLayout>
                  <CreateOrder />
                </AppLayout>
              } />
              <Route path="/category/:categoryName" element={
                <AppLayout>
                  <CategoryPage />
                </AppLayout>
              } />
              <Route path="/product" element={
                <AppLayout>
                  <ProductList />
                </AppLayout>
              } />
              <Route path="/terms" element={
                <AppLayout>
                  <Terms />
                </AppLayout>
              } />
              
              {/* New routes for user profile */}
              <Route path="/profile" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/orders" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/addresses" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/edit-profile" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/wallet" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/referrals" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/support" element={
                <AppLayout>
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                </AppLayout>
              } />

              {/* Address-specific routes */}
              <Route path="/add-address" element={
                <AppLayout>
                  <PrivateRoute>
                    <AddAddressPage />
                  </PrivateRoute>
                </AppLayout>
              } />
              <Route path="/edit-address/:id" element={
                <AppLayout>
                  <PrivateRoute>
                    <AddAddressPage />
                  </PrivateRoute>
                </AppLayout>
              } />
              
              <Route path="*" element={
                <AppLayout>
                  <Navigate to="/" replace />
                </AppLayout>
              } />
            </Routes>
          </Router>
        </LocationProvider>
      </SearchProvider>
    </CartProvider>
  );
}

export default App;