import React from 'react';

const CategoryTile = ({ name, image, category, onCategoryClick }) => {
  return (
    <div 
      onClick={() => onCategoryClick(category)}
      className="flex flex-col items-center cursor-pointer group transition-all duration-300"
    >
      <div className="w-full aspect-square overflow-hidden rounded-lg">
        <img
          src={image}
          alt={name}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
      </div>
      <h3 className="mt-3 text-sm font-medium text-gray-800 text-center whitespace-pre-line">
        {name.includes('&') ? name.split('&').join('&\n') : name}
      </h3>
    </div>
  );
};

const CategoriesGrid = ({ categories, onCategoryClick }) => {
  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-8">
      <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-6">
        {categories.map((category) => (
          <CategoryTile
            key={category.id}
            name={category.name}
            image={category.image}
            category={category.baseCategory}
            onCategoryClick={onCategoryClick}
          />
        ))}
      </div>
    </div>
  );
};

export { CategoryTile, CategoriesGrid };