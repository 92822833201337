import React, { useState, useContext, useEffect } from 'react';
import { Search } from 'lucide-react';
import axios from 'axios';
import { SearchContext } from './SearchContext';

const SearchBar = () => {
  const [inputValue, setInputValue] = useState('');
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const { setSearchResults, setIsSearching, setSearchError } = useContext(SearchContext);
  const apiUrl = process.env.REACT_APP_AUTH_API_URL;

  const placeholderItems = [
    'Butter', 'Milk', 'Salt', 'Sugar', 'Tea Powder', 'Cooking Oil', 'Rice', 
    'Wheat Flour', 'Spices', 'Dal', 'Coffee', 'Ghee', 'Pasta', 'Noodles', 
    'Biscuits', 'Bread', 'Eggs', 'Cheese', 'Vegetables', 'Fruits'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => 
        prevIndex === placeholderItems.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleSearch = async () => {
    if (!inputValue.trim()) return;

    setIsSearching(true);
    setSearchError(null);

    try {
      const response = await axios.get(`${apiUrl}/api/product/search`, {
        params: { query: inputValue }
      });
      setSearchResults(response.data.products);
    } catch (err) {
      setSearchError(err.response?.data?.message || 'Failed to fetch products');
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="relative flex items-center w-full max-w-4xl">
      <div className="absolute left-4 text-black">
        <Search size={20} />
      </div>
      <input
        type="text"
        placeholder={`Search for ${placeholderItems[placeholderIndex]}...`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyUp={handleKeyPress}
        className="w-full h-11 pl-10 pr-4 rounded-xl bg-grey border-none text-black placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-grey"
      />
    </div>
  );
};

export default SearchBar;