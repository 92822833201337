import React, { useState, createContext, useContext, useEffect } from 'react';

// Import icons from an appropriate web library (you can choose what works for your setup)
// This example assumes you're using Lucide React as in your original code
import { MapPin, Search, X, Crosshair, Home, ChevronDown } from 'lucide-react';

// Create a context for location management
const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [savedLocations, setSavedLocations] = useState([
    {
      id: '1',
      name: 'Home',
      address: 'Vijay Nagar, Agra, Uttar Pradesh 282002'
    }
  ]);
  
  const value = {
    currentLocation,
    setCurrentLocation,
    savedLocations,
    setSavedLocations
  };
  
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

// Hook to use location context
export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};

// Location selector component for the app bar
export const LocationSelector = ({ onOpenModal }) => {
  const { currentLocation, savedLocations } = useLocation();
  
  // Display current location if set, otherwise show the first saved location
  const displayAddress = currentLocation?.address || 
                        (savedLocations && savedLocations.length > 0 ? savedLocations[0].address : 'Select your location');
  
  return (
    <button
      onClick={onOpenModal}
      className="flex items-center space-x-2 px-3 py-2 rounded-lg transition-colors"
    >
      <MapPin size={20} className="text-red-500" />
      <div className="flex flex-col items-start">
        <span className="text-lg font-semibold">Delivery in 45 minutes</span>
        <span className="text-sm text-gray-600 truncate max-w-[200px]">
          {displayAddress}
        </span>
      </div>
      <ChevronDown size={16} className="text-gray-700" />
    </button>
  );
};

// Location modal component
export const LocationModal = ({ isOpen, onClose }) => {
  const { setCurrentLocation, savedLocations } = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    setIsLoggedIn(!!token && !!user);
  }, []);

  // Get current location from device
  const detectCurrentLocation = () => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
            // Call Google Maps Geocoding API to get address from coordinates
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
              const address = data.results[0].formatted_address;
              const newLocation = {
                latitude,
                longitude,
                address
              };
              setCurrentLocation(newLocation);
              onClose();
            }
          } catch (error) {
            console.error('Error getting location:', error);
          } finally {
            setIsLoading(false);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleLocationSelect = (location) => {
    setCurrentLocation(location);
    onClose();
  };

  const handleConfirmLocation = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Here you would typically validate the address with a geocoding service
      // For now, we'll just use the search query as the address
      setCurrentLocation({
        address: searchQuery
      });
      setSearchQuery('');
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl max-w-md w-full overflow-hidden">
        <div className="flex justify-center items-center py-4 relative border-b border-gray-100">
          <h2 className="text-lg font-semibold">Your Location</h2>
          <button
            onClick={onClose}
            className="absolute right-4 text-gray-500 hover:text-gray-700"
          >
            <X size={22} />
          </button>
        </div>
        
        <div className="p-4">
          <div className="relative flex items-center bg-gray-100 rounded-lg mb-4">
            <Search size={18} className="absolute left-3 text-gray-500" />
            <input
              type="text"
              placeholder="Search a new address"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-transparent border-none focus:outline-none focus:ring-0"
            />
          </div>
          
          <div className="overflow-y-auto max-h-[60vh]">
            <button
              onClick={detectCurrentLocation}
              className="w-full flex items-center py-4 hover:bg-gray-50 transition-colors"
            >
              <div className="w-9 h-9 rounded-full bg-gray-100 flex items-center justify-center mr-3">
                <Crosshair size={18} className="text-red-500" />
              </div>
              <div className="flex-1 text-left">
                <p className="font-medium">Current Location</p>
                <p className="text-sm text-gray-500">{isLoading ? 'Detecting...' : 'Using GPS'}</p>
              </div>
              {isLoading && (
                <div className="animate-spin h-5 w-5 border-2 border-red-500 border-t-transparent rounded-full" />
              )}
            </button>
            
            {/* Only show saved locations if user is logged in */}
            {isLoggedIn && savedLocations.length > 0 && (
              <>
                <div className="h-px bg-gray-100 my-2 mx-4"></div>
                <p className="px-4 py-2 font-semibold">Saved Location</p>
                
                {savedLocations.map((location) => (
                  <button
                    key={location.id}
                    className="w-full flex items-center py-4 hover:bg-gray-50 transition-colors"
                    onClick={() => handleLocationSelect(location)}
                  >
                    <div className="w-9 h-9 rounded-full bg-gray-100 flex items-center justify-center mr-3">
                      <Home size={18} className="text-purple-700" />
                    </div>
                    <div className="flex-1 text-left">
                      <p className="font-medium">{location.name}</p>
                      <p className="text-sm text-gray-500 line-clamp-2">{location.address}</p>
                    </div>
                  </button>
                ))}
              </>
            )}
            
            {/* No login prompt for non-authenticated users */}
          </div>
          
          {searchQuery.trim() && (
            <form onSubmit={handleConfirmLocation} className="pt-3 border-t border-gray-100 mt-3">
              <button
                type="submit"
                className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition-colors font-medium"
              >
                Confirm Location
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};