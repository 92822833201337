import { useState } from "react";

export default function HelpSupport() {
  const faqs = [
    {
      category: "Ordering & Delivery",
      items: [
        {
          question: "How do I place an order?",
          answer: "You can place an order by browsing products, adding them to your cart, and proceeding to checkout.",
        },
        {
          question: "How can I track my order?",
          answer: "Once your order is placed, you will receive a tracking link via SMS or email.",
        },
        {
          question: "What are the delivery charges?",
          answer: "Delivery charges vary based on your location and order value, displayed at checkout.",
        },
      ],
    },
    {
      category: "Payments & Refunds",
      items: [
        {
          question: "What payment methods do you accept?",
          answer: "We accept UPI, debit/credit cards, net banking, and cash on delivery (COD).",
        },
        {
          question: "My payment failed, but money was deducted. What should I do?",
          answer: "If the payment was deducted but the order was not placed, it will be refunded within 5-7 business days.",
        },
      ],
    },
    {
      category: "Account & Login Issues",
      items: [
        {
          question: "I forgot my password. How can I reset it?",
          answer: "Click on 'Forgot Password' on the login page and follow the instructions to reset it.",
        },
        {
          question: "How do I update my account details?",
          answer: "You can update your name, phone number, and address from the 'My Account' section.",
        },
      ],
    },
    {
      category: "Customer Support & Complaints",
      items: [
        {
          question: "How do I contact customer support?",
          answer: "You can contact us via phone, email, or live chat available on our website.",
        },
        {
          question: "How do I report an issue with my order?",
          answer: "Go to 'My Orders', select the order, and click on 'Report Issue' to get assistance.",
        },
      ],
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold text-left text-[#4A2E23] mb-8">
        Help & Support
      </h1>
      
      {/* Support Contact */}
      <div className="bg-[#3D1F1E] p-6 rounded-lg shadow-md text-center mb-10">
        <h2 className="text-xl font-semibold text-[#FAEDE1]">Need Assistance?</h2>
        <p className="text-[#FAEDE1] mt-2">
          Contact our support team for any queries or issues.
        </p>
        <p className="mt-4 text-bold text-[#FAEDE1]">
          📞 <strong>Phone:</strong> +91 9088409884
        </p>
        <p className="text-[#FAEDE1]">
          📧 <strong>Email:</strong> support@nextdoordukan.com
        </p>
      </div>

      {/* FAQ Section */}
      {faqs.map((section, index) => (
        <FaqSection key={index} category={section.category} items={section.items} />
      ))}
    </div>
  );
}

function FaqSection({ category, items }) {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold text-[#5D4037] mb-4">{category}</h2>
      <div className="space-y-4">
        {items.map((faq, index) => (
          <FaqItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
}

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300 pb-3">
      <button
        className="w-full text-left flex justify-between items-center text-lg font-medium text-gray-800"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className="text-gray-600">{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
}
