import React, { useState } from 'react';
import { X, Loader } from 'lucide-react';
import Logo from '../Logo';

const PhoneModal = ({ isOpen, onClose, onLoginSuccess }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [step, setStep] = useState('phone'); // 'phone', 'otp', or 'userInfo'
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(0);
  const apiUrl = process.env.REACT_APP_AUTH_API_URL;

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and limit to 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      setError('');
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and limit to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      setError('');
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setError('');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    
    if (phoneNumber.length !== 10) {
      setError('Please enter a valid 10-digit phone number');
      return;
    }

    setIsLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${apiUrl}/api/auth/request-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: `91${phoneNumber}` }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setStep('otp');
        // Start a 60-second countdown for resend
        setTimer(60);
        const interval = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 1) {
              clearInterval(interval);
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      } else {
        setError(data.message || 'Failed to send OTP. Please try again.');
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Network error. Please check your connection and try again.');
    } finally {
      setIsLoading(false);
    }
  };

// In your PhoneModal.jsx
// Update the handleVerifyOTP function to call onLoginSuccess

const handleVerifyOTP = async (e) => {
  e.preventDefault();
  
  if (otp.length !== 6) {
    setError('Please enter a valid 6-digit OTP');
    return;
  }

  setIsLoading(true);
  setError('');
  
  try {
    const response = await fetch(`${apiUrl}/api/auth/verify-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        phoneNumber: `91${phoneNumber}`, 
        otp 
      }),
    });
    
    const data = await response.json();
    
    if (response.ok) {
      // Store token now
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      
      // Check if user data is complete
      if (data.user && data.user.name && data.user.email) {
        // Notify parent component of successful login
        if (onLoginSuccess) {
          onLoginSuccess(data);
        }
        // Close the modal
        onClose();
      } else {
        // Pre-populate email field if it exists
        if (data.user.email) {
          setEmail(data.user.email);
        }
        // Pre-populate name field if it exists
        if (data.user.name) {
          setName(data.user.name);
        }
        // Move to user info collection step
        setStep('userInfo');
      }
    } else {
      setError(data.message || 'Invalid OTP. Please try again.');
    }
  } catch (err) {
    console.error('Error verifying OTP:', err);
    setError('Network error. Please check your connection and try again.');
  } finally {
    setIsLoading(false);
  }
};

  const handleSubmitUserInfo = async (e) => {
    e.preventDefault();
    
    if (!name.trim()) {
      setError('Please enter your name');
      return;
    }
    
    if (!email.trim() || !email.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    setError('');
    
    try {
      // Get the token from localStorage to use for authenticated request
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${apiUrl}/api/auth/update-user-info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '', // Include auth header if token exists
        },
        body: JSON.stringify({ 
          phoneNumber: `91${phoneNumber}`, 
          name,
          email
        }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        // Update stored user data with new info
        localStorage.setItem('token', data.token); // Update token if new one is provided
        localStorage.setItem('user', JSON.stringify(data.user));
        
        // Notify parent component and close modal
        if (onLoginSuccess) {
          onLoginSuccess(data);
        }
        onClose();
      } else {
        setError(data.message || 'Failed to update user information. Please try again.');
      }
    } catch (err) {
      console.error('Error updating user info:', err);
      setError('Network error. Please check your connection and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Since we're now handling the login/completion logic directly in the handlers,
  // this helper function is no longer needed
  const resetForm = () => {
    setPhoneNumber('');
    setOtp('');
    setName('');
    setEmail('');
    setStep('phone');
  };

  const handleResendOTP = () => {
    // Reset OTP fields
    setOtp('');
    // Call send OTP function again
    handleSendOTP({ preventDefault: () => {} });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-gradient-to-br bg-white p-8 rounded-2xl w-full max-w-md mx-4">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-black/70 hover:text-black/80 focus:outline-none"
          disabled={isLoading}
        >
          <X size={24} />
        </button>

        <div className="mb-6">
          <Logo />
        </div>

        <div className="space-y-6">
          <h2 className="text-black/70 hover:text-black/80 text-3xl font-bold leading-tight">
            {step === 'phone' && 'Groceries delivered from your local stores'}
            {step === 'otp' && 'Verify WhatsApp OTP'}
            {step === 'userInfo' && 'Just a few more details'}
          </h2>

          {error && (
            <div className="p-3 bg-red-50 text-red-600 rounded-lg text-sm">
              {error}
            </div>
          )}

          {step === 'phone' && (
            <form onSubmit={handleSendOTP} className="space-y-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                  +91
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="Enter Phone Number"
                  className="w-full px-4 py-3 pl-12 rounded-lg bg-black/10 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500"
                  disabled={isLoading}
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-gradient-to-r from-pink-500 to-rose-500 text-white rounded-lg font-medium hover:from-pink-600 hover:to-rose-600 transition-colors flex justify-center items-center"
                disabled={isLoading || phoneNumber.length !== 10}
              >
                {isLoading ? (
                  <>
                    <Loader size={20} className="animate-spin mr-2" />
                    Sending OTP...
                  </>
                ) : (
                  'Get OTP on WhatsApp'
                )}
              </button>
              
              <p className="text-black/70 text-sm text-center">
                We'll send a 6-digit code to your WhatsApp
              </p>
            </form>
          )}

          {step === 'otp' && (
            <form onSubmit={handleVerifyOTP} className="space-y-4">
              <p className="text-sm text-gray-600">
                We've sent a 6-digit OTP to WhatsApp on <span className="font-semibold">+91 {phoneNumber}</span>
              </p>
              
              <div className="space-y-2">
                <label htmlFor="otp" className="text-sm text-gray-700">
                  Enter 6-digit OTP
                </label>
                <input
                  id="otp"
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  placeholder="••••••"
                  className="w-full px-4 py-3 rounded-lg bg-black/10 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 text-center tracking-wider text-lg"
                  maxLength={6}
                  disabled={isLoading}
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-gradient-to-r from-pink-500 to-rose-500 text-white rounded-lg font-medium hover:from-pink-600 hover:to-rose-600 transition-colors flex justify-center items-center"
                disabled={isLoading || otp.length !== 6}
              >
                {isLoading ? (
                  <>
                    <Loader size={20} className="animate-spin mr-2" />
                    Verifying...
                  </>
                ) : (
                  'Verify & Continue'
                )}
              </button>

              <div className="flex justify-center">
                {timer > 0 ? (
                  <p className="text-sm text-gray-500">
                    Resend OTP in {timer} seconds
                  </p>
                ) : (
                  <button
                    type="button"
                    onClick={handleResendOTP}
                    className="text-pink-500 text-sm hover:text-pink-600"
                    disabled={isLoading}
                  >
                    Resend OTP on WhatsApp
                  </button>
                )}
              </div>
              
              <button
                type="button"
                onClick={() => setStep('phone')}
                className="text-gray-500 text-sm hover:text-gray-700 w-full text-center"
                disabled={isLoading}
              >
                Change phone number
              </button>
            </form>
          )}

          {step === 'userInfo' && (
            <form onSubmit={handleSubmitUserInfo} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="name" className="text-sm text-gray-700">
                  Your Name
                </label>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter your full name"
                  className="w-full px-4 py-3 rounded-lg bg-black/10 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500"
                  disabled={isLoading}
                  required
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="email" className="text-sm text-gray-700">
                  Your Email
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email address"
                  className="w-full px-4 py-3 rounded-lg bg-black/10 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500"
                  disabled={isLoading}
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-gradient-to-r from-pink-500 to-rose-500 text-white rounded-lg font-medium hover:from-pink-600 hover:to-rose-600 transition-colors flex justify-center items-center"
                disabled={isLoading || !name.trim() || !email.trim()}
              >
                {isLoading ? (
                  <>
                    <Loader size={20} className="animate-spin mr-2" />
                    Saving...
                  </>
                ) : (
                  'Complete Registration'
                )}
              </button>
            </form>
          )}

          <p className="text-black/70 text-sm text-center">
            By continuing, you agree to our{' '}
            <a href="/terms" className="text-pink-400 hover:text-pink-300">Terms of Service</a>
            {' '}&{' '}
            <a href="#" className="text-pink-400 hover:text-pink-300">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhoneModal;