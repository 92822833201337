import React, { useState, useEffect, useRef } from 'react';
// Update the import at the top of AddressModal.js to include MapPin
import { X, Search, MapPin } from 'lucide-react';
import axios from 'axios';

const AddressModal = ({ isOpen, onClose, addressToEdit = null, onSave }) => {
  const isEditMode = !!addressToEdit;
  const mapRef = useRef(null);
  const searchInputRef = useRef(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  
  const [addressData, setAddressData] = useState({
    tag: 'Home',
    buildingName: '',
    floor: '',
    area: '',
    landmark: '',
    name: '',
    phoneNumber: '',
    location: {
      latitude: null,
      longitude: null
    }
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [addressFromMap, setAddressFromMap] = useState('');

  useEffect(() => {
    // Initialize Google Maps when modal is opened
    if (isOpen && window.google && mapRef.current && !map) {
      initializeMap();
    }
    
    // If editing an existing address, populate the form
    if (addressToEdit) {
      setAddressData(addressToEdit);
      
      // If location data exists, center the map on it
      if (addressToEdit.location?.latitude && addressToEdit.location?.longitude && map) {
        const position = {
          lat: addressToEdit.location.latitude,
          lng: addressToEdit.location.longitude
        };
        
        map.setCenter(position);
        
        // Update marker position
        if (marker) {
          marker.setPosition(position);
        } else {
          const newMarker = new window.google.maps.Marker({
            position,
            map,
            draggable: true,
            animation: window.google.maps.Animation.DROP
          });
          
          setMarker(newMarker);
          
          // Add listener for marker drag end
          newMarker.addListener('dragend', handleMarkerDragEnd);
        }
      }
    } else {
      // Get user data for new address defaults
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        setAddressData(prev => ({
          ...prev,
          name: user.name || '',
          phoneNumber: user.phoneNumber || ''
        }));
      }
    }
  }, [addressToEdit, isOpen, map]);

  const initializeMap = () => {
    // Default location (you can use a default city location)
    const defaultLocation = { lat: 28.5355, lng: 77.3910 }; // Noida, India
    
    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center: defaultLocation,
      zoom: 13,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    });
    
    setMap(mapInstance);
    
    // Create the search box and link it to the UI element
    if (searchInputRef.current) {
      const searchBox = new window.google.maps.places.SearchBox(searchInputRef.current);
      
      // Bias the SearchBox results towards current map's viewport
      mapInstance.addListener('bounds_changed', () => {
        searchBox.setBounds(mapInstance.getBounds());
      });
      
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        
        if (places.length === 0) return;
        
        const place = places[0];
        
        if (!place.geometry || !place.geometry.location) return;
        
        // Update map view
        if (place.geometry.viewport) {
          mapInstance.fitBounds(place.geometry.viewport);
        } else {
          mapInstance.setCenter(place.geometry.location);
          mapInstance.setZoom(17);
        }
        
        // Update marker
        if (marker) {
          marker.setPosition(place.geometry.location);
        } else {
          const newMarker = new window.google.maps.Marker({
            map: mapInstance,
            position: place.geometry.location,
            draggable: true,
            animation: window.google.maps.Animation.DROP
          });
          
          setMarker(newMarker);
          
          // Add listener for marker drag end
          newMarker.addListener('dragend', handleMarkerDragEnd);
        }
        
        // Extract address components
        updateAddressFromPlace(place);
      });
    }
    
    // Add click listener to the map
    mapInstance.addListener('click', (event) => {
      // Update marker position
      if (marker) {
        marker.setPosition(event.latLng);
      } else {
        const newMarker = new window.google.maps.Marker({
          position: event.latLng,
          map: mapInstance,
          draggable: true,
          animation: window.google.maps.Animation.DROP
        });
        
        setMarker(newMarker);
        
        // Add listener for marker drag end
        newMarker.addListener('dragend', handleMarkerDragEnd);
      }
      
      // Get address from coordinates (reverse geocoding)
      getAddressFromLatLng(event.latLng.lat(), event.latLng.lng());
    });
  };

  const handleMarkerDragEnd = () => {
    if (marker) {
      const position = marker.getPosition();
      getAddressFromLatLng(position.lat(), position.lng());
    }
  };

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK' && results[0]) {
          updateAddressFromPlace(results[0]);
        }
      });
      
      // Update location in addressData
      setAddressData(prev => ({
        ...prev,
        location: {
          latitude: lat,
          longitude: lng
        }
      }));
    } catch (error) {
      console.error('Error getting address from coordinates:', error);
    }
  };

  const updateAddressFromPlace = (place) => {
    // Extract address components
    let buildingName = '';
    let area = '';
    
    const streetNumber = place.address_components?.find(component => 
      component.types.includes('street_number')
    );
    
    const route = place.address_components?.find(component => 
      component.types.includes('route')
    );
    
    const sublocality = place.address_components?.find(component => 
      component.types.includes('sublocality') || component.types.includes('sublocality_level_1')
    );
    
    const locality = place.address_components?.find(component => 
      component.types.includes('locality')
    );
    
    // Building name: Combine street number and route
    if (streetNumber) {
      buildingName += streetNumber.long_name;
    }
    
    if (route) {
      buildingName += (buildingName ? ', ' : '') + route.long_name;
    }
    
    // Area: Combine sublocality and locality
    if (sublocality) {
      area += sublocality.long_name;
    }
    
    if (locality) {
      area += (area ? ', ' : '') + locality.long_name;
    }
    
    // Set the formatted address for display
    setAddressFromMap(place.formatted_address);
    
    // Update form fields
    setAddressData(prev => ({
      ...prev,
      buildingName: buildingName || prev.buildingName,
      area: area || prev.area
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setAddressData({
        ...addressData,
        [parent]: {
          ...addressData[parent],
          [child]: value
        }
      });
    } else {
      setAddressData({
        ...addressData,
        [name]: value
      });
    }
  };

  const handleTagSelect = (tag) => {
    setAddressData({
      ...addressData,
      tag
    });
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          
          setAddressData({
            ...addressData,
            location: {
              latitude,
              longitude
            }
          });
          
          // Update map center and marker
          if (map) {
            const pos = { lat: latitude, lng: longitude };
            map.setCenter(pos);
            map.setZoom(17);
            
            if (marker) {
              marker.setPosition(pos);
            } else {
              const newMarker = new window.google.maps.Marker({
                position: pos,
                map,
                draggable: true,
                animation: window.google.maps.Animation.DROP
              });
              
              setMarker(newMarker);
              
              // Add listener for marker drag end
              newMarker.addListener('dragend', handleMarkerDragEnd);
            }
            
            // Get address from coordinates
            getAddressFromLatLng(latitude, longitude);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          setError("Unable to get your current location. Please enter your address manually.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Authentication required. Please log in again.');
        return;
      }

      let response;
      if (isEditMode) {
        // Update existing address
        response = await axios.put(`/api/user/addresses/${addressToEdit._id}`, addressData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      } else {
        // Add new address
        response = await axios.post('/api/user/addresses', addressData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      
      onSave(response.data.address);
      onClose();
    } catch (error) {
      console.error('Error saving address:', error);
      setError(error.response?.data?.message || 'Failed to save address. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-30 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="p-4 border-b border-gray-200 flex items-center justify-between">
          <h2 className="text-xl font-semibold">
            {isEditMode ? 'Edit address' : 'Enter complete address'}
          </h2>
          <button 
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <X size={20} />
          </button>
        </div>
        
        {/* Map Section */}
        <div className="relative">
          {/* Search input over the map */}
          <div className="absolute top-4 left-4 right-4 z-10 bg-white rounded-md shadow-md">
            <div className="relative">
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Search for area, street name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-3 border-none rounded-md focus:ring-0"
              />
              <Search className="absolute left-3 top-3 text-gray-400" size={20} />
              {searchQuery && (
                <button 
                  className="absolute right-3 top-3 text-gray-400"
                  onClick={() => setSearchQuery('')}
                >
                  <X size={16} />
                </button>
              )}
            </div>
          </div>
          
          {/* Map container */}
          <div 
            ref={mapRef} 
            className="w-full h-64"
          ></div>
          
          {/* Current location button */}
          <button
            onClick={getCurrentLocation}
            className="absolute bottom-4 left-4 bg-white py-2 px-4 rounded-md shadow-md text-green-600 font-medium text-sm flex items-center"
          >
            Go to current location
          </button>
          
          {/* Delivering to section */}
          {addressFromMap && (
            <div className="bg-white p-4 border-t border-gray-200">
              <p className="text-sm font-medium text-gray-700 mb-2">Delivering your order to</p>
              <div className="flex items-start">
                <div className="bg-blue-500 rounded-full p-2 mr-3">
                  <MapPin size={16} className="text-white" />
                </div>
                <div>
                  <p className="font-medium">{addressData.area}</p>
                  <p className="text-sm text-gray-500">{addressFromMap}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            {/* Address Type */}
            <div className="mb-6">
              <p className="text-sm font-medium text-gray-700 mb-2">Save address as *</p>
              <div className="flex space-x-3">
                {['Home', 'Work', 'Hotel', 'Other'].map((tag) => (
                  <button
                    key={tag}
                    type="button"
                    className={`py-2 px-4 rounded-full border ${
                      addressData.tag === tag
                        ? 'border-green-500 bg-green-50 text-green-600'
                        : 'border-gray-300 text-gray-700'
                    }`}
                    onClick={() => handleTagSelect(tag)}
                  >
                    {tag}
                  </button>
                ))}
              </div>
            </div>

            {/* Building details */}
            <div className="mb-4">
              <label 
                htmlFor="buildingName" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Flat / House no / Building name *
              </label>
              <input
                type="text"
                id="buildingName"
                name="buildingName"
                value={addressData.buildingName}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Floor */}
            <div className="mb-4">
              <label 
                htmlFor="floor" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Floor (optional)
              </label>
              <input
                type="text"
                id="floor"
                name="floor"
                value={addressData.floor || ''}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Area */}
            <div className="mb-4">
              <label 
                htmlFor="area" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Area / Sector / Locality *
              </label>
              <input
                type="text"
                id="area"
                name="area"
                value={addressData.area}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Landmark */}
            <div className="mb-4">
              <label 
                htmlFor="landmark" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Nearby landmark (optional)
              </label>
              <input
                type="text"
                id="landmark"
                name="landmark"
                value={addressData.landmark || ''}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Delivery Contact */}
            <div className="mb-1">
              <p className="text-sm font-medium text-gray-700 mb-2">
                Enter your details for seamless delivery experience
              </p>
            </div>

            {/* Name */}
            <div className="mb-4">
              <label 
                htmlFor="name" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Your name *
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={addressData.name}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>

            {/* Phone */}
            <div className="mb-6">
              <label 
                htmlFor="phoneNumber" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Your phone number (optional)
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={addressData.phoneNumber || ''}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                pattern="[0-9]{10}"
                placeholder="10-digit mobile number"
              />
            </div>

            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full py-3 bg-green-600 text-white rounded-lg font-medium"
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                  <span>Saving...</span>
                </div>
              ) : (
                isEditMode ? 'Update address' : 'Save address'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;