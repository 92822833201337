import React, { useState } from 'react';
import { Search } from 'lucide-react';

const HeroSection = () => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="flex flex-col lg:flex-row items-center gap-8 px-6 py-12 max-w-4xl mx-auto">
      {/* Image Section */}
      <div className="w-full lg:w-1/2">
        <div className="rounded-2xl">
          <img
            src="https://cdn.usegalileo.ai/sdxl10/87ef2f6c-6749-4f62-bff1-46735faf5aaa.png"
            alt="Woman with groceries"
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
      </div>

      {/* Content Section */}
      <div className="w-full lg:w-1/2 space-y-6">
        <div className="space-y-4">
          <h1 className="text-4xl lg:text-5xl font-black text-[#1B140E] leading-tight">
            Your local grocery store, delivered
          </h1>
          <p className="text-base text-[#1B140E] leading-relaxed">
            Get your favorite groceries from the best local stores delivered to your doorstep
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;