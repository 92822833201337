import React from 'react';
import { ShoppingCart } from 'lucide-react';
import { useCart } from './CartContext';

const CartIcon = ({ onClick }) => {
  const { cartCount } = useCart();
  
  return (
    <div className="relative">
      <button
        onClick={onClick}
        className="relative bg-white p-2 rounded-md
          shadow-[2px_2px_0px_0px_rgba(14,165,233,0.8)] 
          hover:shadow-[4px_4px_0px_0px_rgba(14,165,233,0.8)]
          active:shadow-[1px_1px_0px_0px_rgba(14,165,233,0.8)]
          active:translate-x-[1px]
          active:translate-y-[1px]
          transition-all duration-150"
      >
        <ShoppingCart strokeWidth={1.5} size={28} className="text-[#000000]" />
        {cartCount > 0 && (
          <div className="absolute -top-2 -right-2 bg-orange-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {cartCount}
          </div>
        )}
      </button>
    </div>
  );
};

export default CartIcon;