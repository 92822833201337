import React from 'react';
import { Plus, Minus, Timer } from 'lucide-react';
import { useCart, getUserId } from '../CartContext';

const ProductComponent = ({ product }) => {
    const { state, dispatch } = useCart();
    const userId = getUserId();
    const cartItem = state.carts[userId]?.items?.find(item => item.id === product._id) || null;
    const quantity = cartItem?.quantity || 0;

    const addToCart = (product) => {
        dispatch({
            type: 'ADD_TO_CART',
            payload: { 
                userId: userId,
                item: { 
                    id: product._id, 
                    name: product.ProductName, 
                    image: product.ImageURL, 
                    price: product.price 
                }
            }
        });
    };

    const updateQuantity = (productId, newQuantity) => {
        if (newQuantity === 0) {
            dispatch({
                type: 'REMOVE_FROM_CART',
                payload: { 
                    id: productId,
                    userId: userId 
                }
            });
        } else {
            dispatch({
                type: 'UPDATE_QUANTITY',
                payload: { 
                    id: productId, 
                    quantity: newQuantity,
                    userId: userId
                 }
            });
        }
    };

    const discountPercentage = product.MRP && product.price 
        ? Math.round(((product.MRP - product.price) / product.MRP) * 100) 
        : 0;

    return (
        <div key={product._id} className="bg-white rounded-lg flex flex-col h-full border border-gray-100 relative">

            {/* Product Image */}
            <div className="flex justify-center p-3">
                <img
                    src={product.ImageURL}
                    alt={product.ProductName}
                    className="w-32 h-32 object-contain"
                />
            </div>

            {/* Product Details */}
            <div className="px-3 pb-3 flex flex-col flex-grow">
                {/* Product Name */}
                <h2 className="text-sm font-medium text-gray-800 line-clamp-2">
                    {product.ProductName}
                </h2>

                {/* Weight */}
                <p className="text-xs text-gray-500">{product.Weight}</p>

                {/* Spacer to push the price/button section to bottom */}
                <div className="flex-grow" />

                {/* Price and Add Button Row */}
                <div className="flex items-end justify-between">
                    {/* Price Section - Now uses flex-col with consistent height */}
                    <div className="flex flex-col h-12 justify-end">
                        <span className="text-sm font-semibold">
                            ₹{product.price ?? product.MRP}
                        </span>
                        {product.MRP && product.MRP > product.price && (
                            <span className="text-xs text-gray-400 line-through">₹{product.MRP}</span>
                        )}
                    </div>

                    {/* Add to Cart Button - Fixed width for both states */}
                    <div className="flex-shrink-0">
                        {quantity === 0 ? (
                            <button
                                onClick={() => addToCart(product)}
                                className="bg-white border border-green-500 rounded px-6 py-1 text-green-500 text-sm font-medium hover:bg-green-50 w-24 h-8"
                            >
                                ADD
                            </button>
                        ) : (
                            <div className="flex items-center justify-between bg-green-500 rounded w-24 h-8">
                                <button
                                    onClick={() => updateQuantity(product._id, quantity - 1)}
                                    className="p-1 rounded-l flex-1 flex justify-center"
                                >
                                    <Minus className="w-4 h-4 text-white" strokeWidth={3}/>
                                </button>
                                <span className="text-sm font-medium text-white flex-1 text-center">{quantity}</span>
                                <button
                                    onClick={() => updateQuantity(product._id, quantity + 1)}
                                    className="p-1 rounded-r flex-1 flex justify-center"
                                >
                                    <Plus className="w-4 h-4 text-white" strokeWidth={3}/>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Discount Badge */}
            {discountPercentage > 0 && (
                <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs px-1.5 py-0.5 rounded">
                    {discountPercentage}% OFF
                </div>
            )}
        </div>
    );
}

export default ProductComponent;