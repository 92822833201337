import React, { createContext, useState } from 'react';

// Create context
export const SearchContext = createContext();

// Create provider component
export const SearchProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  return (
    <SearchContext.Provider value={{
      searchResults,
      setSearchResults,
      isSearching,
      setIsSearching,
      searchError,
      setSearchError
    }}>
      {children}
    </SearchContext.Provider>
  );
};