import React, { useState, useEffect } from 'react';
import { CircleUserRound, Menu, ShoppingBag, MessagesSquare, Heart, MapPin, User, LogOut } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from './Logo';
import PhoneModal from './modals/PhoneModal';
import { LocationSelector, LocationModal } from './page_components/LocationComponent';
import CartSidebar from './CartSideBar';
import SearchBar from './SearchBar';
import CartIcon from './CartIcon';
import { useCurrentCart } from './CartContext';

function ResponsiveAppBar() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  
  const navigate = useNavigate();
  const location = useLocation();
  const userCart = useCurrentCart();
  const cartItemCount = userCart.items.length;

  // Navigation items for profile
  const navItems = [
    { id: 'orders', icon: <ShoppingBag size={20} />, label: 'Orders' },
    { id: 'support', icon: <MessagesSquare size={20} />, label: 'Customer Support' },
    { id: 'referrals', icon: <Heart size={20} />, label: 'Manage Referrals' },
    { id: 'addresses', icon: <MapPin size={20} />, label: 'Addresses' },
    { id: 'profile', icon: <User size={20} />, label: 'Profile' }
  ];

  // Check if user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const isUserLoggedIn = !!token && !!user;
    setIsLoggedIn(isUserLoggedIn);
    
    if (isUserLoggedIn && user) {
      try {
        setUserData(JSON.parse(user));
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    const user = localStorage.getItem('user');
    if (user) {
      try {
        setUserData(JSON.parse(user));
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
  };

  const handleProfileClick = () => {
    if (isLoggedIn) {
      // Navigate to profile page if user is logged in
      navigate('/profile');
    } else {
      // Open login modal if user is not logged in
      setIsPhoneModalOpen(true);
    }
  };

  const handleSectionChange = (section) => {
    navigate(`/${section}`);
    setIsMobileMenuOpen(false);
  };

  const handleViewCart = () => {
    setIsCartOpen(true);
  };

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserData(null);
    
    // Redirect to home page
    navigate('/');
    setIsMobileMenuOpen(false);
  };

  // Format phone number for display if needed
  const displayPhone = userData?.phoneNumber ? userData.phoneNumber.replace(/^\+91/, '') : '';

  // Check if current path is a profile page
  const isProfilePage = location.pathname === '/profile' || 
                        location.pathname === '/orders' || 
                        location.pathname === '/addresses' || 
                        location.pathname === '/edit-profile' || 
                        location.pathname === '/wallet' || 
                        location.pathname === '/referrals' || 
                        location.pathname === '/support';

  // Get active section from URL
  const getActiveSection = () => {
    const path = location.pathname.substring(1); // Remove leading slash
    if (path === '') return '';
    
    // Check if any of the nav items match the current path
    const matchingItem = navItems.find(item => item.id === path);
    return matchingItem ? matchingItem.id : path === 'profile' ? 'profile' : '';
  };

  const activeSection = getActiveSection();

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-40 bg-gradient-to-b from-[#a1ebff] to-white">
        <div className="w-full flex justify-center">
          <nav className="relative w-full max-w-[1920px]">
            {/* Desktop Navigation */}
            <div className="hidden md:flex justify-between items-center w-full px-4 lg:px-8 h-20">
              <div className="flex items-center gap-4">
                <Logo />
                <div className="hidden lg:block">
                  <LocationSelector
                    onOpenModal={() => setIsLocationModalOpen(true)}
                  />
                </div>
              </div>
              <div className="flex-1 max-w-2xl mx-4">
                <SearchBar />
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={handleProfileClick}
                  className="relative bg-white p-2 rounded-md
                    shadow-[2px_2px_0px_0px_rgba(14,165,233,0.8)] 
                    hover:shadow-[4px_4px_0px_0px_rgba(14,165,233,0.8)]
                    active:shadow-[1px_1px_0px_0px_rgba(14,165,233,0.8)]
                    active:translate-x-[1px]
                    active:translate-y-[1px]
                    transition-all duration-150"
                >
                  <CircleUserRound size={28} strokeWidth={1.5} className="text-[#000000]" />
                </button>
                <CartIcon onClick={() => setIsCartOpen(true)} />
              </div>
            </div>

            {/* Mobile Navigation */}
            <div className="md:hidden">
              <div className="flex justify-between items-center px-4 h-16">
                <button 
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="p-2"
                >
                  <Menu size={24} />
                </button>
                <div className="flex items-center justify-center scale-75 origin-center">
                  <Logo />
                </div>
                <button
                  onClick={handleProfileClick}
                  className="p-2"
                >
                  <CircleUserRound size={24} strokeWidth={1.5} className="text-[#000000]" />
                </button>
              </div>
              <div className="px-4 pb-3">
                <SearchBar />
              </div>
              
              {/* Mobile Menu */}
              {isMobileMenuOpen && (
                <div className="bg-white shadow-md p-4 animate-fadeIn">
                  {isLoggedIn && (
                    <div className="mb-4 pb-4 border-b border-gray-200">
                      <div className="flex items-center gap-3 mb-4">
                        <div className="bg-purple-500 rounded-full h-10 w-10 flex items-center justify-center">
                          <span className="text-white font-bold">
                            {userData?.name ? userData.name.charAt(0).toUpperCase() : 'U'}
                          </span>
                        </div>
                        <div>
                          <div className="font-semibold">{userData?.name || 'User'}</div>
                          <div className="text-sm text-gray-500">{displayPhone}</div>
                        </div>
                      </div>
                      
                      {/* Profile Navigation Items */}
                      {navItems.map((item) => (
                        <button
                          key={item.id}
                          onClick={() => handleSectionChange(item.id)}
                          className={`flex items-center w-full px-3 py-2 text-left mb-1 rounded-md ${
                            activeSection === item.id 
                              ? 'text-green-600 bg-green-50' 
                              : 'text-gray-700 hover:bg-gray-50'
                          }`}
                        >
                          <span className="mr-3">{item.icon}</span>
                          <span>{item.label}</span>
                        </button>
                      ))}
                      
                      {/* Logout Button */}
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full px-3 py-2 text-left rounded-md text-gray-700 hover:bg-gray-50"
                      >
                        <LogOut size={20} className="mr-3" />
                        <span>Log Out</span>
                      </button>
                    </div>
                  )}
                  
                  <div className="mb-2">
                    <LocationSelector
                      onOpenModal={() => setIsLocationModalOpen(true)}
                    />
                  </div>
                  
                  {!isLoggedIn && (
                    <button
                      onClick={handleProfileClick}
                      className="flex items-center gap-2 w-full p-3 hover:bg-gray-100 rounded-md"
                    >
                      <CircleUserRound size={24} strokeWidth={1.5} className="text-[#000000]" />
                      <span>Account</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>

      <div className="h-20 md:block hidden" />
      <div className="h-28 md:hidden block" />

      {/* Mobile Bottom Cart Bar - Only visible on mobile */}
      {cartItemCount > 0 && (
        <div className="md:hidden fixed bottom-0 left-0 right-0 bg-green-600 text-white z-40">
          <div className="flex items-center p-3">
            <div className="flex-1 flex items-center gap-2">
              <div className="bg-green-700 rounded-full w-6 h-6 flex items-center justify-center text-xs">
                {cartItemCount}
              </div>
              <span>{cartItemCount === 1 ? 'item' : 'items'}</span>
            </div>
            <button 
              onClick={handleViewCart}
              className="flex items-center gap-2 font-medium"
            >
              View Cart
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="ml-1">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Add padding at the bottom to prevent content from being hidden behind the cart bar on mobile */}
      {cartItemCount > 0 && <div className="md:hidden h-14" />}

      {/* Cart Sidebar */}
      <CartSidebar
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
      />

      {/* Phone Modal */}
      <PhoneModal
        isOpen={isPhoneModalOpen}
        onClose={() => setIsPhoneModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />

      {/* Location Modal */}
      <LocationModal
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
      />
    </>
  );
}

export default ResponsiveAppBar;