import React from 'react'

const AboutUs = ({ t }) => {
    return (
        <div className='container mx-auto'>
            <div className='p-4 md:p-6 lg:p-10 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 lg:gap-16'>
                {/* Mission Section */}
                <div className='text-center bg-cyan-200 hover:bg-cyan-250 order-2 md:order-1 rounded-md border-2 border-grey-500 p-4 drop-shadow-md hover:drop-shadow-2xl'>
                    <h1 className='font-mono text-3xl md:text-4xl lg:text-5xl font-extrabold p-2 mb-4'>Our Mission</h1>
                    <p className='text-base md:text-lg lg:text-xl'>{t.missionDescription}</p>
                </div>
                
                {/* Mission Image */}
                <div className='flex justify-center items-center order-1 md:order-2'>
                    <img
                        src="/Logo.png"
                        alt="NextDoor Dukan Logo"
                        className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 object-contain"
                    />
                </div>
                
                {/* Values Image */}
                <div className='flex justify-center items-center order-3'>
                    <img
                        src="/Logo.png"
                        alt="NextDoor Dukan Logo"
                        className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 object-contain"
                    />
                </div>
                
                {/* Values Section */}
                <div className='text-center bg-cyan-200 hover:bg-cyan-250 order-4 rounded-md border-2 border-grey-700 p-4 drop-shadow-md hover:drop-shadow-2xl'>
                    <h1 className='font-mono text-3xl md:text-4xl lg:text-5xl font-bold p-2 mb-4'>Our Values</h1>
                    <p className='text-base md:text-lg lg:text-xl'>{t.visionDescription}</p>
                </div>
            </div>

        </div>
    )
}

export default AboutUs;