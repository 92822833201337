import React, { useState, useEffect } from 'react';
import { ChevronUp, Menu, X } from 'lucide-react';

const TableOfContents = ({ sections, onSectionClick, className }) => (
    <div className={className}>
        <h2 className="text-lg font-semibold mb-4">Table of Contents</h2>
        <ul className="space-y-2">
            {sections.map((section) => (
                <li key={section.id}>
                    <button
                        onClick={() => onSectionClick(section.id)}
                        className="text-left text-blue-600 hover:text-blue-800 cursor-pointer"
                    >
                        {section.title}
                    </button>
                </li>
            ))}
        </ul>
    </div>
);

const TermsPage = () => {
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const sections = [
        { id: "terms-of-use", title: "1. Terms of Use" },
        { id: "access-to-services", title: "2. Access to Services" },
        { id: "eligibility", title: "3. Eligibility to Use" },
        { id: "user-account", title: "4. User Account, Password and Security" },
        { id: "payment", title: "5. Payment Related Information" },
        { id: "pricing", title: "6. Pricing and Products" },
        { id: "delivery", title: "7. Delivery and Returns" },
        { id: "obligations", title: "8. User Obligations" },
        { id: "intellectual-property", title: "9. Intellectual Property Rights" },
        { id: "privacy", title: "10. Privacy and Data Protection" },
        { id: "liability", title: "11. Limitation of Liability" },
        { id: "termination", title: "12. Termination" },
        { id: "dispute", title: "13. Dispute Resolution" },
        { id: "support", title: "14. Customer Support and Grievance Redressal" },
        { id: "changes", title: "15. Changes to Terms" },
        { id: "force-majeure", title: "16. Force Majeure" },
        { id: "severability", title: "17. Severability" },
        { id: "entire-agreement", title: "18. Entire Agreement" },
    ];

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.scrollY > 200);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setShowMobileMenu(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow-sm sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-gray-800">Terms and Services</h1>
                    <button
                        className="md:hidden"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                    >
                        {showMobileMenu ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
            </header>

            {/* Mobile Menu */}
            {showMobileMenu && (
                <div className="fixed inset-0 z-40 bg-white md:hidden overflow-y-auto pt-16">
                    <div className="p-4">
                        <TableOfContents
                            sections={sections}
                            onSectionClick={scrollToSection}
                            className="w-full"
                        />
                    </div>
                </div>
            )}

            {/* Main Content */}
            <div className="container mx-auto px-4 py-8">
                <div className="flex flex-col md:flex-row gap-8">
                    {/* Desktop Sidebar */}
                    <aside className="md:w-64 hidden md:block sticky top-24 h-fit">
                        <TableOfContents
                            sections={sections}
                            onSectionClick={scrollToSection}
                            className="bg-white p-6 rounded-lg shadow-sm"
                        />
                    </aside>

                    {/* Main Content */}
                    <main className="flex-1 max-w-3xl">
                        {/* Introduction */}
                        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
                            <p className="text-gray-600 text-sm mb-4">Last Updated: February 14, 2025</p>
                            <p className="text-gray-700 leading-relaxed">
                                This document is an electronic record and published in accordance with the provisions of the Information Technology Act, 2000 and the rules thereunder and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 and generated by a computer system and does not require any physical or digital signatures.
                            </p>
                        </div>

                        {/* Terms Sections */}
                        <div className="space-y-8">
                            {/* Section 1 */}
                            <section id="terms-of-use" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">1. Terms of Use</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            1.1. The website www.nextdoordukan.com ("Website") and mobile application 'NextDoorDukan' (App) (Website and App collectively referred to as the "Platform") is owned, operated and managed by NextDoorDukan Private Limited, a private limited company incorporated in accordance with the provisions of the Indian Companies Act, 2013 and having its registered office situated at [Your Registered Office Address].                    </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            1.2. These terms of use ("Terms") govern your use of the Platform, and for the purpose of these Terms, NextDoorDukan Private Limited including its subsidiaries, its holding company, its affiliates may wherever context so require, be also referred to as, "NextDoorDukan", "We", "Us", or "Our" and the terms "You", "Your" or "User" refer to user of the Platform. We value the trust You have placed in Us and hence We maintain reasonable security standards for securing the transactions and Your information.                    </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            1.3. Please read these Terms carefully before You use the Platform. If You do not agree to these Terms, You may not use the services on the Platform, and We request You to uninstall the App and not access the Platform. By merely using/accessing the Platform, You shall be contracting with NextDoorDukan and You signify Your acceptance to these Terms and other NextDoorDukan policies (including but not limited to the cancellation & refund Terms and published privacy policy ("Privacy Notice") as posted on the Platform and amended from time to time, which takes effect on the date on which You use the Platform and thereby create a legally binding agreement to abide by the same.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            1.4. NextDoorDukan retains an unconditional right to modify or amend these Terms. You can determine when these Terms were last modified by referring to the 'Last Updated' legend above. You can access the latest version of these Terms at any given time on the Platform. You should regularly review the Terms on the Platform. NextDoorDukan reserves the right to amend, suspend, discontinue or add any or all services without prior notice and can add or remove relevant terms and conditions, if necessary.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            {/* Section 2 */}
                            <section id="access-to-services" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Access to Services</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            2.1. NextDoorDukan enables transactions on the Platform between participating sellers and service providers ("Seller(s)") and Users. Users can order Products (defined hereunder) and services offered for sale by these Sellers in select serviceable areas across India. NextDoorDukan is not and cannot be a party to or control in any manner any transaction between the Platform's Users and Sellers.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            2.2. You acknowledge that the Platform allows You to avail a personal, limited, non-exclusive, non-transferable, and revocable privilege to access and use the Platform for the purposes of purchasing consumer goods such as grocery, apparel, electronics, health and wellness products, etc. (collectively, "Product(s)") from the Sellers.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            2.3. NextDoorDukan does not: (i) adopt any 'unfair trade practices' either on the Platform or otherwise with respect to its services; (ii) discriminate between Users of the same class or make any arbitrary classification of the Users and (iii) discriminate between the third-party delivery service providers.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            2.4. NextDoorDukan functions solely as an online marketplace and acts as a facilitator enabling transactions between Users and Sellers on the Platform. At no point does NextDoorDukan take possession of any Products offered by Sellers nor does it hold any rights, title or interest in those Products and services.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            {/* Section 3 */}
                            <section id="eligibility" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">3. Eligibility to Use</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            3.1. The Products and services are not available to minors i.e., persons under the age of 18 (eighteen) years, undischarged insolvent, any Users who are not competent to enter into a contract under the Indian Contract Act, 1872, unless otherwise provided hereunder or to anyone previously suspended or removed by NextDoorDukan from availing the Products or services or accessing the Platform.                                    </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            3.2. You will not discriminate against third-party delivery service providers based on race, religion, caste, creed, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other metric which is deemed to be immoral and unlawful.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="user-account" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">4. User Account, Password and Security</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            4.1. In order to access the services of the Platform, You will have to register and create an account on the Platform by providing details as may be required ("Account").
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            4.2. You are solely responsible for maintaining the confidentiality of Your account information and password. NextDoorDukan shall not be liable for any unauthorized use of Your account.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="payment" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Payment Related Information</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            5.1. The Platform accepts various payment methods which shall be displayed during the purchasing process.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            5.2. You undertake to provide valid payment details and You represent and warrant that You are authorized to use any payment method You provide.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            5.3. NextDoorDukan is not liable for:
                                        </p>
                                        <ul className="list-disc pl-8 mt-2 space-y-1 text-gray-600">
                                            <li>Insufficient funds in Your account</li>
                                            <li>Technical issues with payment processing</li>
                                            <li>Unauthorized transactions</li>
                                            <li>Expired payment methods</li>
                                            <li>Any other circumstances beyond NextDoorDukan's reasonable control</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>

                            <section id="pricing" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">6. Pricing and Products</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            6.1. All prices are determined by the Sellers and NextDoorDukan does not influence pricing decisions.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            6.2. Prices may be subject to change without notice.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            6.3. All prices are in Indian Rupees and include applicable taxes unless otherwise stated.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="delivery" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">7. Delivery and Returns</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            7.1. Delivery timeframes are estimates only and may vary based on location and other factors.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            7.2. Returns may be accepted within [X] days of delivery, subject to the condition and nature of the product.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            7.3. Refunds will be processed through the original payment method within [X] business days of return approval.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="obligations" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">8. User Obligations</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            8.1. You agree to:
                                        </p>
                                        <ul className="list-disc pl-8 mt-2 space-y-1 text-gray-600">
                                            <li>Provide accurate information</li>
                                            <li>Maintain account security</li>
                                            <li>Use the Platform legally and responsibly</li>
                                            <li>Not engage in any fraudulent activity</li>
                                            <li>Not misuse the Platform or its services</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>

                            <section id="intellectual-property" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">9. Intellectual Property Rights</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            9.1. All content on the Platform is owned by or licensed to NextDoorDukan.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            9.2. Users may not copy, reproduce, modify, or distribute any Platform content without express permission.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="privacy" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">10. Privacy and Data Protection</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            10.1. Your use of the Platform is governed by our Privacy Notice.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            10.2. We collect and process personal data in accordance with applicable laws.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="liability" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">11. Limitation of Liability</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            11.1. NextDoorDukan shall not be liable for:
                                        </p>
                                        <ul className="list-disc pl-8 mt-2 space-y-1 text-gray-600">
                                            <li>Any indirect, incidental, special, or consequential damages</li>
                                            <li>Loss of profits or data</li>
                                            <li>Service interruptions</li>
                                            <li>Third-party actions</li>
                                            <li>Force majeure events</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>

                            <section id="termination" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">12. Termination</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            12.1. NextDoorDukan may terminate or suspend Your account at any time for violations of these Terms.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            12.2. You may terminate Your account at any time by contacting customer support.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="dispute" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">13. Dispute Resolution</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            13.1. Any disputes shall be subject to the exclusive jurisdiction of courts in [Your City], India.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="support" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">14. Customer Support and Grievance Redressal</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            14.1. For support, contact:<br />
                                            Email: support@nextdoordukan.com<br />
                                            Phone: [Your Support Number]
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            14.2. Grievance Officer:<br />
                                            Name: [Officer Name]<br />
                                            Email: grievances@nextdoordukan.com
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="changes" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">15. Changes to Terms</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            15.1. NextDoorDukan reserves the right to modify these Terms at any time.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            15.2. Continued use of the Platform after changes constitutes acceptance of the modified Terms.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="force-majeure" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">16. Force Majeure</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            16.1. NextDoorDukan shall not be liable for any failure to perform due to causes beyond its reasonable control.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="severability" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">17. Severability</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            17.1. If any provision of these Terms is found to be unenforceable, the remaining provisions will continue in full force.
                                        </p>
                                    </div>
                                </div>
                            </section>

                            <section id="entire-agreement" className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">18. Entire Agreement</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-gray-600 leading-relaxed">
                                            18.1. These Terms constitute the entire agreement between You and NextDoorDukan regarding the use of the Platform.
                                        </p>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </main>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-white border-t mt-12 py-6">
                <div className="container mx-auto px-4 text-center text-gray-600">
                    <p>© 2025 NextDoorDukan Private Limited. All rights reserved.</p>
                </div>
            </footer>

            {/* Scroll to Top Button */}
            {showScrollTop && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-8 right-8 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors"
                    aria-label="Scroll to top"
                >
                    <ChevronUp size={24} />
                </button>
            )}
        </div>
    );
};

export default TermsPage;