import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ShoppingBag, MessagesSquare, Heart, MapPin, User, LogOut, Plus, MoreVertical, Home, Briefcase, Hotel, Check, Edit, Trash2 } from 'lucide-react';
import axios from 'axios';
import AddressModal from './AddressModal';

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('profile');
  const [activeMenu, setActiveMenu] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get user data from localStorage
    const userData = localStorage.getItem('user');
    if (!userData) {
      // Redirect to home if no user is logged in
      navigate('/');
      return;
    }

    try {
      const parsedUser = JSON.parse(userData);
      setUser(parsedUser);

      // Determine active section from URL path
      const path = location.pathname;
      if (path.includes('orders')) {
        setActiveSection('orders');
      } else if (path.includes('support')) {
        setActiveSection('support');
      } else if (path.includes('referrals')) {
        setActiveSection('referrals');
      } else if (path.includes('addresses')) {
        setActiveSection('addresses');
        // Fetch addresses when on addresses page
        fetchAddresses();
      } else if (path.includes('wallet')) {
        setActiveSection('wallet');
      } else {
        setActiveSection('profile');
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error parsing user data:', error);
      navigate('/');
    }
  }, [navigate, location.pathname]);

  const fetchAddresses = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await axios.get('/api/user/addresses', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      setAddresses(response.data.addresses || []);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    
    // Redirect to home page
    navigate('/');
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    
    // Update the URL without page refresh
    navigate(`/${section}`);
    
    // If changing to addresses section, fetch addresses
    if (section === 'addresses') {
      fetchAddresses();
    }
  };

  const getAddressIcon = (tag) => {
    switch(tag.toLowerCase()) {
      case 'home':
        return <Home className="text-yellow-500" size={20} />;
      case 'work':
        return <Briefcase className="text-yellow-500" size={20} />;
      case 'hotel':
        return <Hotel className="text-yellow-500" size={20} />;
      default:
        return <Home className="text-yellow-500" size={20} />;
    }
  };

  const handleAddNewAddress = () => {
    setAddressToEdit(null);
    setShowAddressModal(true);
  };

  const handleEditAddress = (address) => {
    setAddressToEdit(address);
    setShowAddressModal(true);
    setActiveMenu(null);
  };

  const handleSaveAddress = (savedAddress) => {
    if (addressToEdit) {
      // Update existing address in the state
      setAddresses(addresses.map(addr => 
        addr._id === savedAddress._id ? savedAddress : addr
      ));
    } else {
      // Add new address to the state
      setAddresses([...addresses, savedAddress]);
    }
  };

  const toggleMenu = (index) => {
    if (activeMenu === index) {
      setActiveMenu(null);
    } else {
      setActiveMenu(index);
    }
  };

  const handleSetDefault = async (addressId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/user/addresses/${addressId}/set-default`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Update addresses in state - mark this one as default, others as not default
      setAddresses(addresses.map(addr => ({
        ...addr,
        isDefault: addr._id === addressId
      })));
      
      setActiveMenu(null);
    } catch (error) {
      console.error('Error setting default address:', error);
    }
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/user/addresses/${addressId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Remove the address from state
      setAddresses(addresses.filter(addr => addr._id !== addressId));
      setActiveMenu(null);
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  if (loading || !user) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  // Format phone number for display if needed
  const displayPhone = user.phoneNumber ? user.phoneNumber.replace(/^\+91/, '') : '';

  // Navigation items
  const navItems = [
    { id: 'orders', icon: <ShoppingBag size={20} />, label: 'Orders' },
    { id: 'support', icon: <MessagesSquare size={20} />, label: 'Customer Support' },
    { id: 'referrals', icon: <Heart size={20} />, label: 'Manage Referrals' },
    { id: 'addresses', icon: <MapPin size={20} />, label: 'Addresses' },
    { id: 'profile', icon: <User size={20} />, label: 'Profile' }
  ];

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col md:flex-row">
      {/* Sidebar navigation - hidden on mobile, 1/4 width on desktop */}
      <div className="hidden md:block md:w-1/4 bg-white md:min-h-screen border-r border-gray-200">
        {/* User Info */}
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center gap-3">
            <div className="bg-purple-500 rounded-full h-10 w-10 flex items-center justify-center">
              <span className="text-white font-bold">
                {user.name ? user.name.charAt(0).toUpperCase() : 'U'}
              </span>
            </div>
            <div>
              <div className="font-semibold">{user.name || 'User'}</div>
              <div className="text-sm text-gray-500">{displayPhone}</div>
            </div>
          </div>
        </div>
        
        {/* Navigation Links */}
        <nav className="mt-2">
          {navItems.map((item) => (
            <button
              key={item.id}
              onClick={() => handleSectionChange(item.id)}
              className={`flex items-center w-full px-4 py-3 text-left ${
                activeSection === item.id 
                  ? 'text-green-600 bg-green-50' 
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <span className="mr-3">{item.icon}</span>
              <span>{item.label}</span>
            </button>
          ))}
          
          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-3 text-left text-gray-700 hover:bg-gray-50"
          >
            <LogOut size={20} className="mr-3" />
            <span>Log Out</span>
          </button>
        </nav>
      </div>
      
      {/* Main content area - full width on mobile, 3/4 width on desktop */}
      <div className="w-full md:w-3/4 p-4">
        {/* Orders Section */}
        {activeSection === 'orders' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Your Orders</h2>
            <div className="flex flex-col items-center justify-center text-center py-10">
              <div className="bg-pink-100 rounded-lg p-4 w-20 h-20 flex items-center justify-center mb-4">
                <span className="text-4xl text-pink-500">Z</span>
              </div>
              <h3 className="text-lg font-medium mb-2">No orders yet</h3>
              <p className="text-gray-500 mb-4">Looks like you haven't placed any orders yet</p>
              <button
                onClick={() => navigate('/')}
                className="border border-purple-500 text-purple-500 font-medium py-2 px-4 rounded-lg"
              >
                Browse products
              </button>
            </div>
          </div>
        )}
        
        {/* Profile Section */}
        {activeSection === 'profile' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Profile Information</h2>
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <input 
                  type="text" 
                  value={user.name || ''} 
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                <input 
                  type="text" 
                  value={displayPhone} 
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input 
                  type="email" 
                  value={user.email || 'Not provided'} 
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  readOnly
                />
              </div>
            </form>
          </div>
        )}
        
        {/* Addresses Section */}
        {activeSection === 'addresses' && (
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-xl font-semibold">Your Addresses</h2>
              <button 
                onClick={handleAddNewAddress}
                className="flex items-center text-green-600 font-medium"
              >
                <Plus size={20} className="mr-1" />
                Add new
              </button>
            </div>
            <div className="p-4">
              {addresses.length === 0 ? (
                <div className="text-center py-10">
                  <p className="text-gray-500">No saved addresses</p>
                </div>
              ) : (
                <div className="space-y-4">
                  {addresses.map((address, index) => (
                    <div key={index} className="border border-gray-200 rounded-lg p-4 relative">
                      {address.isDefault && (
                        <div className="absolute top-2 right-2 bg-green-100 text-green-600 text-xs px-2 py-1 rounded-full">
                          Default
                        </div>
                      )}
                      <div className="flex justify-between items-start">
                        <div className="flex items-start">
                          <div className="mr-3 mt-1">
                            {getAddressIcon(address.tag)}
                          </div>
                          <div className="flex-1 min-w-0">
                            <h3 className="font-medium text-gray-800 lowercase">{address.tag}</h3>
                            <p className="text-gray-600 text-sm mt-1">{address.buildingName}</p>
                            <p className="text-gray-600 text-sm mt-0.5 break-words">
                              {[
                                address.area,
                                address.floor ? `Floor ${address.floor}` : null,
                                address.landmark ? address.landmark : null
                              ]
                                .filter(Boolean)
                                .join(', ')}
                            </p>
                            <p className="text-gray-600 text-sm mt-0.5">
                              {address.name}, {address.phoneNumber || 'No phone provided'}
                            </p>
                          </div>
                        </div>
                        <div className="relative ml-2 flex-shrink-0">
                          <button 
                            className="p-1" 
                            onClick={() => toggleMenu(index)}
                          >
                            <MoreVertical size={20} className="text-gray-500" />
                          </button>
                          
                          {/* Dropdown Menu */}
                          {activeMenu === index && (
                            <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-10">
                              {!address.isDefault && (
                                <button
                                  onClick={() => handleSetDefault(address._id)}
                                  className="flex items-center w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                                >
                                  <Check size={16} className="mr-2" />
                                  Set as default
                                </button>
                              )}
                              <button
                                onClick={() => handleEditAddress(address)}
                                className="flex items-center w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                              >
                                <Edit size={16} className="mr-2" />
                                Edit address
                              </button>
                              <button
                                onClick={() => handleDeleteAddress(address._id)}
                                className="flex items-center w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-100"
                              >
                                <Trash2 size={16} className="mr-2" />
                                Delete address
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        
        {/* Placeholder content for other sections */}
        {(activeSection === 'support' || 
          activeSection === 'referrals') && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">
              {activeSection === 'support' && 'Customer Support'}
              {activeSection === 'referrals' && 'Manage Referrals'}
            </h2>
            <p className="text-center text-gray-500 py-10">
              This feature is coming soon!
            </p>
          </div>
        )}
      </div>

      {/* Address Modal */}
      <AddressModal
        isOpen={showAddressModal}
        onClose={() => {
          setShowAddressModal(false);
          setAddressToEdit(null);
        }}
        addressToEdit={addressToEdit}
        onSave={handleSaveAddress}
      />
    </div>
  );
};

export default ProfilePage;