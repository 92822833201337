import React, { useEffect, useState } from 'react';
import { Plus, Minus, Timer } from 'lucide-react';
import { useCart } from './CartContext';
import { useParams } from 'react-router-dom';

const ProductCard = ({ product, onAddToCart, onUpdateQuantity, quantity }) => {
    const discountPercentage = product.MRP && product.price 
        ? Math.round(((product.MRP - product.price) / product.MRP) * 100) 
        : 0;

    return (
        <div className="bg-white rounded-lg flex flex-col h-full border border-gray-100 relative">
            {/* Delivery Time */}
            {/* <div className="flex items-center gap-1 px-3 pt-3 text-gray-500">
                <Timer size={14} />
                <span className="text-xs">45 MINS</span>
            </div> */}

            {/* Product Image */}
            <div className="flex justify-center p-3">
                <img
                    src={product.ImageURL}
                    alt={product.ProductName}
                    className="w-32 h-32 object-contain"
                />
            </div>

            {/* Product Details */}
            <div className="px-3 pb-3 flex flex-col flex-grow">
                {/* Product Name */}
                <h2 className="text-sm font-medium text-gray-800 line-clamp-2">
                    {product.ProductName}
                </h2>

                {/* Weight */}
                <p className="text-xs text-gray-500">{product.Weight}</p>

                <div className="flex-grow" />

                {/* Price and Add Button Row */}
                <div className="flex items-end justify-between">
                    {/* Price Section */}
                    <div className="flex flex-col h-12 justify-end">
                        <span className="text-sm font-semibold">
                            ₹{product.price ?? product.MRP}
                        </span>
                        {product.MRP && product.MRP > product.price && (
                            <span className="text-xs text-gray-400 line-through">₹{product.MRP}</span>
                        )}
                    </div>

                    {/* Add to Cart Button */}
                    <div className="flex-shrink-0">
                        {quantity === 0 ? (
                            <button
                            onClick={() => onAddToCart(product)}
                            className="bg-white border border-green-500 rounded px-6 py-1 text-green-500 text-sm font-medium hover:bg-green-50 w-24 h-8"
                            >
                                ADD
                            </button>
                        ) : (
                            <div className="flex items-center justify-between bg-green-500 rounded w-24 h-8">
                                <button
                                    onClick={() => onUpdateQuantity(product._id, quantity - 1)}
                                    className="p-1 rounded-l flex-1 flex justify-center"
                                >
                                    <Minus className="w-4 h-4 text-white" strokeWidth={3}/>
                                </button>
                                <span className="text-sm font-medium text-white flex-1 text-center">{quantity}</span>
                                <button
                                    onClick={() => onUpdateQuantity(product._id, quantity + 1)}
                                    className="p-1 rounded-r flex-1 flex justify-center"
                                >
                                    <Plus className="w-4 h-4 text-white" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Discount Badge */}
            {discountPercentage > 0 && (
                <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs px-1.5 py-0.5 rounded">
                    {discountPercentage}% OFF
                </div>
            )}
        </div>
    );
};

const CategoryPage = () => {
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { categoryName } = useParams();
    const { state, dispatch } = useCart();
    const userId = 1;

    const addToCart = (product) => {
        dispatch({
            type: 'ADD_TO_CART',
            payload: { 
                userId,
                item: { 
                    id: product._id, 
                    name: product.ProductName, 
                    image: product.ImageURL, 
                    price: product.price 
                }
            }
        });
    };

    const updateQuantity = (productId, newQuantity) => {
        if (newQuantity === 0) {
            dispatch({
                type: 'REMOVE_FROM_CART',
                payload: { userId, id: productId }
            });
        } else {
            dispatch({
                type: 'UPDATE_QUANTITY',
                payload: { userId, id: productId, quantity: newQuantity }
            });
        }
    };

    useEffect(() => {
        const fetchCategoryProducts = async () => {
            try {
                const apiUrl = process.env.REACT_APP_AUTH_API_URL;
                const response = await fetch(`${apiUrl}/api/product?baseCategory=${encodeURIComponent(categoryName)}`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                
                const data = await response.json();
                setCategoryProducts(data || []);
            } catch (error) {
                console.error('Fetch error:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryProducts();
    }, [categoryName]);

    if (loading) return (
        <div className="flex justify-center items-center min-h-[200px]">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
        </div>
    );

    if (error) return (
        <div className="text-center text-red-500 py-8">
            Error: {error}
        </div>
    );

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="flex items-center mb-6">
                <h1 className="text-xl font-semibold text-gray-800">
                    {decodeURIComponent(categoryName)}
                </h1>
                <span className="text-sm text-gray-500 ml-2">
                    ({categoryProducts.length} items)
                </span>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
                {categoryProducts.map(product => {
                    const cartItem = state.carts[userId]?.items?.find(item => item.id === product._id) || null;
                    const quantity = cartItem?.quantity || 0;

                    return (
                        <ProductCard
                            key={product._id}
                            product={product}
                            onAddToCart={addToCart}
                            onUpdateQuantity={updateQuantity}
                            quantity={quantity}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CategoryPage;