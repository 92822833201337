import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from './SearchContext';
import ProductComponent from './page_components/ProductComponent';
import HeroSection from './page_components/HeroSection';
import { CategoriesGrid } from './page_components/CategoryTile';

const SHOPS = [
    { id: 's1', name: 'Gupta Store', address: 'Paliwal Park' },
    { id: 's2', name: 'Ekta Provisional Store', address: 'Gandhi Nagar' },
    { id: 's3', name: 'Shree Ram Chemist', address: 'Old Vijay Nagar Colony' },
    { id: 's4', name: 'Shree Ram Super Store', address: 'Lajpatkunj, Khandari' },
    { id: 's5', name: 'Kaila Devi Store', address: 'New Vijay Nagar' },
    { id: 's6', name: 'Sanajy Store', address: 'Paliwal Park' },
];

// Import all images
const CATEGORIES = [
    {
        id: 'p1',
        name: 'Dairy, Bread & Eggs',
        image: require('./assets/images/dairy-bread.png'),
        baseCategory: 'Dairy, Bread & Eggs'
    },
    {
        id: 'p2',
        name: 'Atta & Rice',
        image: require('./assets/images/atta-rice.png'),
        baseCategory: 'Atta, Rice'
    },
    {
        id: 'p3',
        name: 'Cooking Oil & Ghee',
        image: require('./assets/images/oil-ghee.png'),
        baseCategory: 'Oil, Ghee & Dal'
    },
    {
        id: 'p4',
        name: 'Masala & Dry Fruits',
        image: require('./assets/images/masala-dryfruit.png'),
        baseCategory: 'Masala & DryFruits'
    },
    {
        id: 'p5',
        name: 'Salt, Sugar & Jaggery',
        image: require('./assets/images/salt-sugar.png'),
        baseCategory: 'Salt, Sugar & Jaggery'
    },
    {
        id: 'p6',
        name: 'Breakfast & Ketchup',
        image: require('./assets/images/cereals.png'),
        baseCategory: 'Breakfast & Ketchup'
    },
    {
        id: 'p7',
        name: 'Tea & Coffee',
        image: require('./assets/images/tea-coffee.png'),
        baseCategory: 'Tea & Coffee'
    },
    {
        id: 'p8',
        name: 'Noodles & Pasta',
        image: require('./assets/images/noodle-pasta.png'),
        baseCategory: 'Noodles & Pasta'
    },
    {
        id: 'p9',
        name: 'Papad & Pickles',
        image: require('./assets/images/papad-pickle.png'),
        baseCategory: 'Papads, Pickles'
    },
    {
        id: 'p10',
        name: 'Frozen Food',
        image: require('./assets/images/frozen-food.png'),
        baseCategory: 'Frozen Food'
    },
    {
        id: 'p11',
        name: 'Chocolates & Toffees',
        image: require('./assets/images/choclates-sweets.png'),
        baseCategory: 'Chocolates, Pastries & Candies'
    },
    {
        id: 'p12',
        name: 'Cold Drinks & Juices',
        image: require('./assets/images/colddrink-juice.png'),
        baseCategory: 'Cold Drinks & Juices'
    },
    {
        id: 'p13',
        name: 'Chips & Namkeen',
        image: require('./assets/images/snacks-namkeen.png'),
        baseCategory: 'Chips & Namkeeen'
    },
    {
        id: 'p14',
        name: 'Biscuits & Cookies',
        image: require('./assets/images/biscuit-cakes.png'),
        baseCategory: 'Biscuits & Cookies'
    },
    {
        id: 'p15',
        name: 'Bath & Body',
        image: require('./assets/images/bath-body.png'),
        baseCategory: 'Bath and Body '
    },
    {
        id: 'p16',
        name: 'Skin Care',
        image: require('./assets/images/skin-care.png'),
        baseCategory: 'Skincare'
    },
    {
        id: 'p17',
        name: 'Baby Care',
        image: require('./assets/images/baby-care.png'),
        baseCategory: 'Baby Care'
    },
    {
        id: 'p18',
        name: 'Shampoo & Hair Oil',
        image: require('./assets/images/hair-care.png'),
        baseCategory: 'Shampoo & Hair Oil'
    },
    {
        id: 'p19',
        name: 'Perfumes & Grooming',
        image: require('./assets/images/grooming.png'),
        baseCategory: 'Fragrances & Grooming'
    },
    {
        id: 'p20',
        name: 'Feminine Hygiene',
        image: require('./assets/images/feminine-hygiene.png'),
        baseCategory: 'Feminine Hygiene'
    },
    {
        id: 'p21',
        name: 'Cleaning Essentials',
        image: require('./assets/images/cleaning.png'),
        baseCategory: 'Cleaning Essentials'
    },
    {
        id: 'p22',
        name: 'Pet Care',
        image: require('./assets/images/pet-supplies.png'),
        baseCategory: 'Pet Care'
    }
];

const ProductsPage = ({ category }) => {
    const navigate = useNavigate();
    const { searchResults, isSearching, searchError } = useContext(SearchContext);
    const [displayCategories, setDisplayCategories] = useState(
        category
            ? CATEGORIES.filter(p => p.baseCategory === category)
            : CATEGORIES
    );

    useEffect(() => {
        setDisplayCategories(
            category
                ? CATEGORIES.filter(p => p.baseCategory === category)
                : CATEGORIES
        );
    }, [category]);

    const handleCategoryClick = (category) => {
        navigate(`/category/${encodeURIComponent(category)}`);
    };

    const showCategories = searchResults.length === 0;

    return (
        <div className="flex flex-col justify-center w-full py-4 items-center">
            {/* Horizontal Scrollable Shops */}

            <div className="container mx-auto py-5">
                <div className="max-w-7xl mx-auto">
                    {/* Hero Section */}
                    <HeroSection />

                    <div className="w-full px-4 sm:px-6 md:px-8 mb-8">
                    <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4">Search By Shop</h2>
                    
                    <div className="relative w-full">
                        {/* Gradient indicators for scrolling */}
                        <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent z-10 pointer-events-none"></div>
                        <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none"></div>
                        
                        {/* Scrollable container */}
                        <div className="flex space-x-3 sm:space-x-4 md:space-x-6 py-2 overflow-x-auto scrollbar-hide">
                        {SHOPS.map((shop) => (
                            <div 
                            key={shop.id} 
                            className="flex-shrink-0 w-44 sm:w-48 md:w-52 h-20 sm:h-24 bg-white border border-gray-100 rounded-lg shadow-sm 
                                        hover:shadow-md hover:border-gray-200 transition-all 
                                        flex flex-col items-center justify-center 
                                        text-gray-700 cursor-pointer p-2"
                            >
                            <p className="text-sm sm:text-md font-semibold text-center line-clamp-1">{shop.name}</p>
                            <p className="text-xs sm:text-sm text-gray-500 text-center">{shop.address}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                    </div>

                    {showCategories ? (
                        // Show categories with new component
                        <CategoriesGrid
                            categories={displayCategories}
                            onCategoryClick={handleCategoryClick}
                        />
                    ) : (
                        // Show search results
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 px-4">
                            {searchResults.map((product) => (
                                <ProductComponent
                                    key={product._id}
                                    product={product}
                                    onProductClick={handleCategoryClick}
                                />
                            ))}
                        </div>
                    )}

                    {!showCategories && searchResults.length === 0 && !isSearching && (
                        <div className="text-center text-gray-500 mt-8">
                            No products found
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductsPage;