import React, { createContext, useContext, useReducer, useEffect } from 'react';

const CartContext = createContext();

// Get user ID from local storage or session
export const getUserId = () => {
  // Try to get authenticated user
  const userStr = localStorage.getItem('user');
  if (userStr) {
    try {
      const user = JSON.parse(userStr);
      return user._id || 'guest'; // Use MongoDB ID from our auth system
    } catch (e) {
      console.error('Error parsing user from storage:', e);
    }
  }
  return 'guest'; // Use 'guest' for unauthenticated users
};

const cartReducer = (state, action) => {
  // Get the current userId for operations (default to 'guest' if not provided)
  const userId = action.payload?.userId || getUserId();
  const userCart = state.carts[userId] || { items: [] };

  let newState;

  switch (action.type) {
    case 'ADD_TO_CART': {
      const existingItem = userCart.items.find(item => item.id === action.payload.item.id);
      
      const updatedItems = existingItem
        ? userCart.items.map(item =>
            item.id === action.payload.item.id ? { ...item, quantity: item.quantity + 1 } : item
          )
        : [...userCart.items, { ...action.payload.item, quantity: 1 }];

      newState = {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...userCart, items: updatedItems },
        },
      };
      break;
    }

    case 'REMOVE_FROM_CART': {
      const updatedItems = userCart.items.filter(item => item.id !== action.payload.id);

      newState = {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...userCart, items: updatedItems },
        },
      };
      break;
    }

    case 'UPDATE_QUANTITY': {
      const updatedItems = userCart.items.map(item =>
        item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
      );

      newState = {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...userCart, items: updatedItems },
        },
      };
      break;
    }

    case 'MERGE_GUEST_CART': {
      // This action merges a guest cart into the user's cart after login
      const guestCart = state.carts['guest'] || { items: [] };
      const currentUserCart = state.carts[userId] || { items: [] };
      
      // Merge items, handling duplicates by keeping the higher quantity
      const mergedItems = [...currentUserCart.items];
      
      guestCart.items.forEach(guestItem => {
        const existingItemIndex = mergedItems.findIndex(item => item.id === guestItem.id);
        
        if (existingItemIndex >= 0) {
          // Item exists, keep the higher quantity
          mergedItems[existingItemIndex].quantity = Math.max(
            mergedItems[existingItemIndex].quantity,
            guestItem.quantity
          );
        } else {
          // Add new item
          mergedItems.push({...guestItem});
        }
      });
      
      newState = {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...currentUserCart, items: mergedItems },
          // Optionally clear the guest cart
          'guest': { items: [] }
        },
      };
      break;
    }

    case 'SYNC_WITH_SERVER': {
      // Replace the current cart with the one from server
      newState = {
        ...state,
        carts: {
          ...state.carts,
          [userId]: action.payload.serverCart,
        },
      };
      break;
    }

    case 'SET_CURRENT_USER': {
      newState = {
        ...state,
        currentUserId: action.payload.currentUserId,
      };
      break;
    }

    default:
      return state;
  }

  // Save to localStorage after every state change
  localStorage.setItem('cartState', JSON.stringify(newState));
  return newState;
};

export const CartProvider = ({ children }) => {
  // Initialize state from localStorage if available
  const initialState = JSON.parse(localStorage.getItem('cartState')) || { 
    carts: {},
    currentUserId: getUserId()  
  };
  const [state, dispatch] = useReducer(cartReducer, initialState);
  
  // Listen for auth changes
  useEffect(() => {
    const handleStorageChange = () => {
      const currentUserId = getUserId();
      const previousUserId = state.currentUserId;
      
      // If user changed (login or logout happened)
      if (currentUserId !== previousUserId) {
        // If logged in and there was a guest cart, merge them
        if (currentUserId !== 'guest' && state.carts['guest']?.items.length > 0) {
          dispatch({ 
            type: 'MERGE_GUEST_CART', 
            payload: { userId: currentUserId } 
          });
        }
        
        // Update the current user ID in state
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: { currentUserId }
        });
        
        // If we have a server API, we could also fetch the user's cart from server here
        // fetchUserCart(currentUserId, token).then(serverCart => {
        //   dispatch({ type: 'SYNC_WITH_SERVER', payload: { userId: currentUserId, serverCart } });
        // });
      }
    };
    
    // Initial check
    handleStorageChange();
    
    // Listen for storage changes that might indicate login/logout
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [state.currentUserId]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

// Helper hook to get the current user's cart
export const useCurrentCart = () => {
  const { state } = useCart();
  const userId = getUserId();
  return state.carts[userId] || { items: [] };
};